<template>
  <div>
    <bread-cum :breadcrumbItems="['จัดการสมาชิก', 'รายการแทง']" />
    <div class="row mb-3">
      <div class="col-6">
        <div class="card border-primary mb-2">
          <div class="card-header border-primary bg-primary text-white">
            ข้อมูลเมมเบอร์
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>ID</label>
                  <input class="form-control w-100" :value="user.id" disabled />
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                <button v-if="['COMPANY', 'AGENT'].includes(currentUser.level)" class="btn btn-danger"
                  @click="loginAsMember">
                  login as member
                </button>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>ชื่อผู้ใช้</label>
                  <input class="form-control w-100" :value="user.username" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>ชื่อ</label>
                  <input class="form-control w-100" :value="user.name" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>เครดิต</label>
                  <input class="form-control w-100" :value="util.formatMoney(user.creditBalance)" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>เครดิตแนะนำเพื่อน</label>
                  <input class="form-control w-100" :value="util.formatMoney(user.affiliateBalance)" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>สมัครเมื่อ</label>
                  <input class="form-control w-100" :value="$date(user.createdAt).format('DD/MM/YYYY HH:mm:ss')"
                    disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>อัพเดตล่าสุด</label>
                  <input class="form-control w-100" :value="$date(user.updatedAt).format('DD/MM/YYYY HH:mm:ss')"
                    disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>สถานะ</label>
                  <input class="form-control w-100" :value="user.statusTH" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-primary">
          <div class="card-header border-primary bg-primary text-white">
            บัญชีธนาคาร
          </div>
          <div class="card-body">
            <select class="form-control w-100 mb-2" v-model="bankAccountIndex">
              <option v-for="(item, index) in userBankAccount" :key="index" :value="index">
                {{ item.accountName }} ({{ item.accountNumber }}) ({{
                  item.bankCode
                }})
              </option>
            </select>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>ชื่อบัญชี</label>
                  <input class="form-control w-100" :value="bankAccount.accountName" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>ธนาคาร</label>
                  <input class="form-control w-100" :value="bankAccount.bankCode" disabled />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>เลขบัญชี</label>
                  <input class="form-control w-100" :value="bankAccount.accountNumber" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card border-primary mb-2">
          <div class="card-header border-primary bg-primary text-white">
            ได้เสีย
          </div>
          <div class="card-body">
            <div class="row text-center">
              <div class="col-12 d-flex flex-column" :class="{
                positive: user.companyWin > 0,
                negative: user.companyWin < 0,
              }">
                <span class="h1 mb-0">{{
                  util.formatMoney(user.companyWin)
                }}</span>
                <span class="h6">ได้เสีย</span>
              </div>
              <div class="col-6 d-flex flex-column" :class="{
                    positive: user.depositAmount > 0,
                    negative: user.depositAmount < 0,
                  }">
                <span class="h1 mb-0">{{
                  util.formatMoney(user.depositAmount)
                }}</span>
                <span class="h6">ฝาก</span>
              </div>
              <div class="col-6 d-flex flex-column" :class="{
                    positive: user.withdrawAmount < 0,
                    negative: user.withdrawAmount > 0,
                  }">
                <span class="h1 mb-0">{{
                  util.formatMoney(user.withdrawAmount)
                }}</span>
                <span class="h6">ถอน</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-primary mb-2">
          <div class="card-header border-primary bg-primary text-white">
            สมาชิกแนะนำ
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>ID</label>
                  <input class="form-control w-100" :value="userAf.id" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>ชื่อผู้ใช้</label>
                  <input class="form-control w-100" :value="userAf.username" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-primary mb-2">
          <div class="card-header border-primary bg-primary text-white">
            เทิร์นโอเวอร์
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>ทำเทิร์นไปแล้ว</label>
                  <input class="form-control w-100" :value="userTurnover.turnoverAmount" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>ต้องทำเทิร์น</label>
                  <input class="form-control w-100" :value="userTurnover.targetTurnoverAmount" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-primary">
          <div class="card-header border-primary bg-primary text-white">
            ฝากถอน
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>เล่นก่อนถอน</label>
                  <input class="form-control w-100" :value="betBeforeWithdrawAmount" disabled />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <button v-show="canEditMember" class="btn btn-danger"
                    @click="resetBetBeforeWithdrawAmount">reset</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-card no-body>
      <b-tabs card v-model="tabDetailIndex">
        <b-tab title="ประวัติการแทงหวย">
          <b-card-text>
            <member-huay-bill v-if="tabDetailIndex === 0"></member-huay-bill>
          </b-card-text>
        </b-tab>
        <b-tab title="ตะกร้าสลาก">
          <b-card-text>
            <member-huay-cart v-if="tabDetailIndex === 1"></member-huay-cart>
          </b-card-text>
        </b-tab>
        <b-tab title="ประวัติการเล่นเกม">
          <b-card-text>
            <member-game-bet v-if="tabDetailIndex === 2"></member-game-bet>
          </b-card-text>
        </b-tab>
        <b-tab title="ประวัติการเล่นเกมทั้งหมด">
          <b-card-text>
            <user-game-bet v-if="tabDetailIndex === 3"></user-game-bet>
          </b-card-text>
        </b-tab>
        <b-tab title="แจ้งฝากถอน">
          <b-card-text>
            <user-transfer-request :user-id="$route.params.userId" v-if="tabDetailIndex === 4"></user-transfer-request>
          </b-card-text>
        </b-tab>
        <b-tab title="ประวัติการเงิน">
          <b-card-text>
            <credit-transfer-history v-if="tabDetailIndex === 5"></credit-transfer-history>
          </b-card-text>
        </b-tab>
        <b-tab title="แนะนำเพื่อน">
          <b-card-text>
            <user-affiliate v-if="tabDetailIndex === 6"></user-affiliate>
          </b-card-text>
        </b-tab>
        <b-tab title="รายได้จากการแนะนำเพื่อน">
          <b-card-text>
            <affiliate-statement v-if="tabDetailIndex === 7"></affiliate-statement>
          </b-card-text>
        </b-tab>
        <b-tab title="โปรโมชั่น">
          <b-card-text>
            <user-promotion v-if="tabDetailIndex === 8"></user-promotion>
          </b-card-text>
        </b-tab>
        <b-tab title="บัญชีธนาคาร">
          <b-card-text>
            <bank-account v-if="tabDetailIndex === 9"></bank-account>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import MemberGameBet from "@/components/MemberGameBet.vue"
import MemberHuayBill from "@/components/MemberHuayBill.vue"
import UserTransferRequest from "@/components/UserTransferRequest.vue"
import AffiliateStatement from "@/components/AffiliateStatement.vue"
import CreditTransferHistory from "@/components/CreditTransferHistory.vue"
import UserAffiliate from "@/components/UserAffiliate.vue"
import UserPromotion from "@/components/UserPromotion.vue"
import UserGameBet from "@/components/UserGameBet.vue"
import BankAccount from "@/components/BankAccount.vue"
import MemberHuayCart from "@/components/MemberHuayCart.vue"

export default {
  components: {
    MemberHuayBill,
    MemberGameBet,
    UserTransferRequest,
    AffiliateStatement,
    CreditTransferHistory,
    UserAffiliate,
    UserPromotion,
    UserGameBet,
    BankAccount,
    MemberHuayCart,
  },
  data() {
    return {
      isLoading: true,
      user: {},
      userAf: {},
      huayBillItems: [],
      userBankAccount: [],
      bankAccount: {},
      bankAccountIndex: 0,
      tabDetailIndex: 0,
      userTurnover: {},
      betBeforeWithdrawAmount: 0,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user.userData
    },
    canEditMember() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("edit_member_member_edit")
    },
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name || to.params.userId != from.params.userId) {
        this.$store.dispatch("auth/forceReload")
      }
    },
    bankAccountIndex() {
      this.bankAccount = this.userBankAccount[this.bankAccountIndex]
    },
  },
  methods: {
    async resetBetBeforeWithdrawAmount() {
      this.swal.processing()
      try {
        await this.axios({
          method: "post",
          url: "users/reset/bet-before-withdraw",
          data: {
            id: parseInt(this.$route.params.userId)
          },
        })
        await this.swal.success()
        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        this.swal.catchError(e)
      }
    },
    async loginAsMember() {
      if (this.user.level != "MEMBER") {
        this.swal.swalError("สามารถ login ได้เฉพาะ member เท่านั้น")
        return
      }

      this.swal.processing()
      try {
        const { data } = await this.axios.get(
          "users/login-as-member?userId=" + this.$route.params.userId
        )

        const resData = data.data

        this.swal.close()

        this.openTabMember(resData.url)
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    openTabMember(url) {
      const userAgent = window.navigator.userAgent
      if (userAgent.includes("Mobile", -1) && userAgent.includes("Line", -1)) {
        window.location.href = url
      }

      const windowReference = window.open()
      windowReference.location = url
    },
    async getUserDetail() {
      this.swal.processing()

      try {
        const { data } = await this.axios.get(
          "users/detail/" + this.$route.params.userId
        )

        const resData = data.data

        this.user = resData.user
        this.userAf = resData.userAf
        this.userTurnover = resData.userTurnover
        this.betBeforeWithdrawAmount = resData.betBeforeWithdrawAmount

        this.user.companyWin =
          this.user.depositAmount - this.user.withdrawAmount

        this.userBankAccount = resData.userBankAccount

        if (this.userBankAccount.length > 0) {
          this.bankAccount = this.userBankAccount[0]
        }

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
        })
      }
    },
  },
  mounted() {
    this.getUserDetail()
  },
}
</script>