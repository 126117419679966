<template>
  <div>
    <CDataTable :items="carts" :fields="fields" :column-filter="{ external: externalFilter }"
      :items-per-page-select="{ label: 'จำนวนแถว' }" :table-filter-value.sync="tableFilterValue"
      :column-filter-value.sync="columnFilterValue" :items-per-page="itemsPerPage" :loading="tableLoading" :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }" v-on:pagination-change="paginationChange" v-on:update:column-filter-value="columnFilter" :sorter="false"
      pagination hover dark border size="sm" add-table-classes="text-center">
      <template #more="{ item }">
        <td>
          <b-button class="mr-2" size="sm" v-b-modal.modal-1 @click="getHuayCartItem(item)">เพิ่มเติม</b-button>
          <button v-if="item.status == 'OPEN'" class="btn btn-sm btn-danger" @click="cancelCart(item)"
            v-show="canCancelCart">
            ยกเลิกตะกร้า
          </button>
        </td>
      </template>
    </CDataTable>
    <CPagination :active-page="activePage" :double-arrows="false" :pages="totalPage" :dots="false"
      v-on:update:activePage="updateActivePage" />
    <b-modal id="modal-1" size="lg" body-bg-variant="dark" body-text-variant="light" header-bg-variant="dark"
      header-text-variant="light" footer-bg-variant="dark" footer-text-variant="light" hide-footer title="ตะกร้าสลาก">
      <table class="table table-sm table-dark table-bordered table-striped w-100">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>#ID</th>
            <th>เลข</th>
            <th>จำนวน</th>
            <th>ราคาแทง</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in huayCartItems" :key="index">
            <td>{{ index + 1 }}.</td>
            <td>{{ item.id }}</td>
            <td>{{ item.number }}</td>
            <td>{{ item.numberOfItem }}</td>
            <td>{{ util.formatMoneyNormal(item.buyAmount) }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carts: [],
      isLoading: true,
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
        },
        {
          key: "numberOfItem",
          label: "จำนวนใบ",
          filter: false,
        },
        {
          key: "buyAmount",
          label: "ราคาที่ต้องจ่าย",
          filter: false,
        },
        {
          key: "createdAt",
          label: "สร้างเมื่อ",
          filter: false,
        },
        {
          key: "updatedAt",
          label: "อัพเดตล่่าสุด",
          filter: false,
        },
        {
          key: "createdBy",
          label: "สร้างโดย",
          filter: false,
        },
        {
          key: "updatedBy",
          label: "อัพเดตโดย",
          filter: false,
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "more",
          label: "",
          filter: false,
        },
      ],
      tableFilterValue: "",
      columnFilterValue: {},
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: false,
      externalFilter: true,
      huayCartItems: [],
    }
  },
  computed: {
    canCancelCart() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("cancel_cart")
    },
  },
  methods: {
    setPage(val) {
      this.$route.query.page = val
    },
    async getHuayCartItem(item) {
      this.swal.processing()

      try {
        const { data } = await this.axios({
          method: "get",
          url: "huay/cart/item/" + item.id,
        })

        this.huayCartItems = data.data

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async getHuayCart() {
      this.swal.processing()

      try {
        const { data } = await this.axios.get(
          "huay/cart/" + this.$route.params.userId,
          {
            params: {
              page: this.activePage,
              pageSize: this.itemsPerPage,
              ...this.columnFilterValue,
            },
          }
        )

        const resData = data.data

        resData?.forEach((item) => {
          item.buyAmount = this.util.formatMoney(item.buyAmount)

          let statusClass = ""
          if (item.status == "OPEN") {
            statusClass = "positive"
          } else if (item.status == "CLOSE") {
            statusClass = "negative"
          }

          item._cellClasses = {
            status: statusClass,
          }

          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.updatedAt = this.$date(item.updatedAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
        })

        this.carts = resData

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
        })
      }
    },
    paginationChange(val) {
      this.itemsPerPage = val

      this.getUserHuayBill()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.bills &&
        this.bills.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val

      this.getUserHuayBill()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.getUserHuayBill()
      }, 500)
    },
    async cancelCart(item) {
      let confirm = await this.swal.confirm(
        "ยกเลิกตะกร้า",
        "ท่านต้องการยกเลิกตะกร้าใช่หรือไม่"
      )
      if (!confirm) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "/huay/cart/item/" + item.id,
        })

        await this.swal.success()

        this.getHuayCart()
      } catch (e) {
        console.log(e)
        this.swal.swalError(e.response.data.description)
      }
    },
  },
  mounted() {
    this.getHuayCart()
  },
}
</script>